import React from "react"
import styled from "styled-components"
const StyledDiv = styled.div`
  width: 80%;
  margin: auto;
  min-height: 70vh;
  @media (max-width: 1024px) {
    width: 95%;
  }
`
const Div8095 = ({ children }) => <StyledDiv>{children}</StyledDiv>
export default Div8095
