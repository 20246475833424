import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledCard = styled.div`
  border: solid 1px black;
  background-color: white;
  width: 12em;
  margin: 1em 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const StyledPic = styled.img`
  width: 80%;
  margin-top: 1em;
`
const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`
const StyledSpan = styled.span`
  border-bottom: solid 1px transparent;
  transition: border-color 1s linear;
  margin-bottom: 1em;
  &:hover {
    border-color: #2a3546;
  }
`
const WriterCard = ({ fullname, slug, image }) => {
  return (
    <StyledLink to={"/writers/" + slug}>
      <StyledCard>
        <StyledPic
          src={require(`../../images/writers/${image}`)}
          alt={fullname}
        />
        <StyledSpan>{fullname}</StyledSpan>
      </StyledCard>
    </StyledLink>
  )
}

export default WriterCard
