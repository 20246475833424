import React from "react"
import styled from "styled-components"
const StyledDiv = styled.h2`
  text-align: center;
  background-color: #f1c761;
  color: #2a3546;
  font-size: 1.3em;
  padding: 1em;
  border-style: double;
  width: ${props => props.divWidth};
  margin: auto;
  @media (max-width: 750px) {
    width: 100%;
  }
`
const HeadingCard = ({ children, w }) => (
  <StyledDiv className="rancho" divWidth={w || "60%"}>
    {children}
  </StyledDiv>
)
export default HeadingCard
