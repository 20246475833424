import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import writers from "../json/writers.json"
import WriterCard from "../components/writerspage/WriterCard"
import Div8095 from "../components/utils/Div8095"
import styled from "styled-components"
import ContainerDiv from "../components/utils/ContainerDiv"
import HeadingCard from "../components/utils/HeadingCard"

const StyledFlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

const Writers = () => {
  const writersList = writers.map((e, i) => {
    return (
      <WriterCard key={i} fullname={e.fullname} image={e.image} slug={e.slug} />
    )
  })
  return (
    <Layout>
      <SEO title={"Writers"} />
      <ContainerDiv bg="#527e7d">
        <Div8095>
          <HeadingCard>Get to Know our Talented Writers</HeadingCard>
          <StyledFlexBox>{writersList}</StyledFlexBox>
        </Div8095>
      </ContainerDiv>
    </Layout>
  )
}

export default Writers
