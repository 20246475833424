import React from "react"
import styled from "styled-components"
const StyledDiv = styled.div`
  background-color: ${props => props.bg};
  margin: 0;
  padding: 1em 0;
`
const ContainerDiv = ({ children, bg }) => (
  <StyledDiv bg={bg}>{children}</StyledDiv>
)
export default ContainerDiv
